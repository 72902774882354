enum Path {
  HOME = '/',
  SHOP = '/shop',
  CART_SUMMARY = '/cart',
  CART_CHECKOUT = '/checkout',
  CART_PAYMENTS = '/payment',
  CART_PAYMENTS_SUCCESS = '/order/:orderId/success',
  PRODUCT = '/product/:productId',
  PRODUCTS_LIST = '/products/:type',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  CONTACT = '/contact',
}

const getProductsListLink = (type: string): Path => (
  Path.PRODUCTS_LIST.replace(':type', type) as Path
);

const getProductLink = (id: number): Path => (
  Path.PRODUCT.replace(':productId', String(id)) as Path
);

export default Path;
export {
  getProductLink,
  getProductsListLink,
};
