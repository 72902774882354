import currency from 'currency.js';

const displayPrice = (amount: number, quantity: number = 1) => currency(amount, {
  symbol: 'zł',
  pattern: `#${'\u00A0'}!`,
  precision: 2,
  separator: `${'\u00A0'}`,
  decimal: ',',
}).multiply(quantity).divide(100).format();

const displayPricePerQt = (amount: number) => currency(amount, {
  symbol: 'zł/szt',
  pattern: `#${'\u00A0'}!`,
  precision: 2,
  separator: `${'\u00A0'}`,
  decimal: ',',
}).divide(100).format();

export { displayPrice, displayPricePerQt };
