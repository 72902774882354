import React, { FC } from 'react';
import classes from './FixWidthContent.module.less';

const FixWidthContent: FC = ({ children }) => (
  <div className={classes.content}>
    {children}
  </div>
);

export default FixWidthContent;
