import placeholder from '../assets/placeholder.svg';

const getPhotoSrc = (photoUrl: string): string => {
  if (!photoUrl) {
    return placeholder;
  }

  return photoUrl;
};

export { getPhotoSrc };
