import React, { FC } from 'react';
import { Layout } from 'antd';
import classes from './AppContent.module.less';

const { Content } = Layout;
const AppContent: FC = ({ children }) => (
  <Content className={classes.container}>
    {children}
  </Content>
);

export default AppContent;
