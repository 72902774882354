import { Selector } from 'react-redux';
import { RootState } from '../../../redux/store';
import slice from './slice';
import { CartItem, CartItemDataApi } from '../../../types';
import { Order } from '../../../types/Order.types';
import { Payment } from '../../../types/Payment.types';

export const getCartItems: Selector<RootState, CartItem[]> = (state) => (
  state[slice.name].products
);

export const getCartTotal: Selector<RootState, number> = (state) => (
  state[slice.name].total
);

export const getCartItemsData: Selector<RootState, CartItemDataApi[]> = (state) => (
  (state[slice.name].products || []).map((item: CartItem) => ({
    product_id: item.productId,
    quantity: item.quantity,
    link: item.attributes?.link,
    metadata: item.metadata,
  }))
);

export const getCartItemsCount: Selector<RootState, number> = (state) => (
  state[slice.name].products.length
);

export const isOpenCartList: Selector<RootState, boolean> = (state) => (
  state[slice.name].isCartListOpen
);

export const getOrder: Selector<RootState, Order> = (state) => (
  state[slice.name].order
);

export const getIsLoading: Selector<RootState, boolean> = (state) => (
  state[slice.name].isLoading
);

export const getPayment: Selector<RootState, Payment> = (state) => (
  state[slice.name].payment
);
