import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.less';
import Path from './constants/Path';
import NotFoundRoute from './routes/NotFoundRoute';

const {
  HOME,
  SHOP,
  CART_SUMMARY,
  CART_CHECKOUT,
  CART_PAYMENTS,
  CART_PAYMENTS_SUCCESS,
  PRODUCT,
  PRODUCTS_LIST,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  CONTACT,
} = Path;

const publicRoutes = [
  HOME,
  PRODUCT,
  PRODUCTS_LIST,
  CART_SUMMARY,
  CART_CHECKOUT,
  CART_PAYMENTS,
  CART_PAYMENTS_SUCCESS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  CONTACT,
];

if (process.env.REACT_APP_LOADING_PAGE) {
  publicRoutes.push(SHOP);
}

const LoadingPageLayout = lazy(() => import('./layouts/LoadingPageLayout'));
const CartLayout = lazy(() => import('./layouts/CartLayout'));
const DefaultLayout = lazy(() => import('./layouts/DefaultLayout'));
const HomeView = lazy(() => import('./views/home/HomeView'));
const LoadingPageView = lazy(() => import('./views/loading-page/LoadingPageView'));
const ProductsListView = lazy(() => import('./views/products-list/ProductsListView'));
const ProductView = lazy(() => import('./views/product/ProductView'));
const OrderPaymentSuccessView = lazy(() => import('./views/cart/OrderPaymentSuccess/OrderPaymentSuccessView'));
const PrivacyPolicyView = lazy(() => import('./views/privacy-policy/PrivacyPolicyView'));
const TermsAndConditionsView = lazy(() => import('./views/terms-and-conditions/TermsAndConditionsView'));
const ContactView = lazy(() => import('./views/contact/ContactView'));
const CartPaymentView = lazy(() => import('./views/cart/CartPayment/CartPaymentView'));
const CartCheckoutView = lazy(() => import('./views/cart/CartCheckout/CartCheckoutView'));
const CartSummaryView = lazy(() => import('./views/cart/CartSummary/CartSummaryView'));

function Routes() {
  return (
    <Switch>
      <Route exact path={publicRoutes}>
        {
          process.env.REACT_APP_LOADING_PAGE && (
          <Route exact path={HOME}>
            <LoadingPageLayout>
              <LoadingPageView />
            </LoadingPageLayout>
          </Route>
          )
        }
        <Route exact path={process.env.REACT_APP_LOADING_PAGE ? SHOP : HOME}>
          <DefaultLayout>
            <HomeView />
          </DefaultLayout>
        </Route>
        <Route exact path={PRODUCT}>
          <DefaultLayout>
            <ProductView />
          </DefaultLayout>
        </Route>
        <Route exact path={PRODUCTS_LIST}>
          <DefaultLayout>
            <ProductsListView />
          </DefaultLayout>
        </Route>
        <Route exact path={PRIVACY_POLICY}>
          <DefaultLayout>
            <PrivacyPolicyView />
          </DefaultLayout>
        </Route>
        <Route exact path={TERMS_AND_CONDITIONS}>
          <DefaultLayout>
            <TermsAndConditionsView />
          </DefaultLayout>
        </Route>
        <Route exact path={CONTACT}>
          <DefaultLayout>
            <ContactView />
          </DefaultLayout>
        </Route>
        <Route exact path={CART_CHECKOUT}>
          <CartLayout>
            <CartCheckoutView />
          </CartLayout>
        </Route>
        <Route exact path={CART_SUMMARY}>
          <CartLayout>
            <CartSummaryView />
          </CartLayout>
        </Route>

        <Route exact path={CART_PAYMENTS}>
          <CartLayout>
            <CartPaymentView />
          </CartLayout>
        </Route>
        <Route exact path={CART_PAYMENTS_SUCCESS}>
          <CartLayout>
            <OrderPaymentSuccessView />
          </CartLayout>
        </Route>
      </Route>
      { NotFoundRoute }
    </Switch>
  );
}

export default Routes;
