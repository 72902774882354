import React, { FC } from 'react';
import { Layout } from 'antd';
import AppContent from '../components/AppContent/AppContent';
import AppFooter from '../components/AppFooter';
import classes from './DefaultLayout.module.less';
import CartSideList from '../components/CartSideList';
import DefaultHeader from '../components/Headers/DefaultHeader';

const DefaultLayout: FC = ({ children }) => (
  <Layout className={classes.main}>
    <DefaultHeader />
    <Layout className={classes.content}>
      <AppContent>
        {children}
      </AppContent>
      <CartSideList />
    </Layout>
    <AppFooter />
  </Layout>
);

export default DefaultLayout;
