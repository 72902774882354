import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import history from './getBrowserHistory';
import plCommon from './locales/pl/common';
import plProduct from './locales/pl/product';
import plCart from './locales/pl/cart';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: {
        common: plCommon,
        product: plProduct,
        cart: plCart,
      },
    },
    lng: 'pl',
    fallbackLng: 'pl',
  });
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
