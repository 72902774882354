import React, { FC } from 'react';
import {
  Row, Col, Layout, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from './AppFooter.module.less';
import Path, { getProductsListLink } from '../../constants/Path';

const { Footer } = Layout;

const AppFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <Footer
      className={classes.footerContainer}
    >
      <Row
        gutter={[
          {
            xs: 62, md: 0, lg: 0, xl: 0, xxl: 0,
          },
          {
            xs: 62, md: 0, lg: 0, xl: 0, xxl: 0,
          },
        ]}
        className={classes.footer}
      >
        <Col
          className={classes.col}
          xs={12}
          sm={12}
          md={12}
          lg={6}
        >
          <Typography.Title level={4} className={classes.footerTitle}>
            {t('common:footer.headers.important')}
          </Typography.Title>
          <Link to={Path.TERMS_AND_CONDITIONS} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:footer.termsAndConditions')}</Typography.Paragraph>
          </Link>
          <Link to={Path.PRIVACY_POLICY} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:footer.privacyPolicy')}</Typography.Paragraph>
          </Link>
          <Link to={Path.CONTACT} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:footer.contact')}</Typography.Paragraph>
          </Link>
          <Link to={Path.CONTACT} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:footer.api')}</Typography.Paragraph>
          </Link>
        </Col>
        <Col
          className={classes.col}
          xs={12}
          sm={12}
          md={12}
          lg={6}
        >
          <Typography.Title level={4} className={classes.footerTitle}>
            {t('common:footer.headers.services')}
          </Typography.Title>
          <Link to={getProductsListLink('instagram')} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:type.instagram')}</Typography.Paragraph>
          </Link>
          <Link to={getProductsListLink('facebook')} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:type.facebook')}</Typography.Paragraph>
          </Link>
          <Link to={getProductsListLink('tiktok')} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:type.tiktok')}</Typography.Paragraph>
          </Link>
          <Link to={getProductsListLink('youtube')} className={classes.footerLink}>
            <Typography.Paragraph>{t('common:type.youtube')}</Typography.Paragraph>
          </Link>
        </Col>
      </Row>
      <div className={classes.copyright}>
        <Typography.Text className={classes.faded}>
          {t('common:footer.copyright', { year: new Date().getFullYear() })}
        </Typography.Text>
      </div>
    </Footer>
  );
};

export default AppFooter;
