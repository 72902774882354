import { Cart, CartItem, CartItemApi } from '../../types';

export class CartTransformer {
  static transform(cartData: any): Cart {
    return ({
      id: cartData.id,
      total: cartData.total,
      paymentType: cartData.payment_type,
      customerEmail: cartData.customer_email,
      products: cartData.products.map((product: CartItemApi) => this.transformProduct(product)),
    });
  }

  private static transformProduct(product: CartItemApi): CartItem {
    return ({
      id: product.id,
      productId: product.product_id,
      name: product.name,
      pictureUrl: product.picture_url,
      type: product.type,
      minQuantity: product.min_quantity,
      maxQuantity: product.max_quantity,
      quantity: product.quantity,
      price: product.price,
      currency: product.currency,
      metadata: product.metadata,
      attributes: {
        link: product.link,
      },
    });
  }
}
