import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Spin } from 'antd';
import './App.less';
import Routes from './Routes';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_ID || 'GTM-WFNNK8T',
};

TagManager.initialize(tagManagerArgs);

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Spin spinning />}>
      <div className="App">
        <Routes />
      </div>
    </Suspense>
  );
}

export default App;
