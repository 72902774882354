import axios, { AxiosResponse } from 'axios';
import { CartTransformer } from './transformer/cart.transformer';
import { Cart, Product } from '../types';
import { Order } from '../types/Order.types';
import { Payment } from '../types/Payment.types';

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const getAxiosInstance = () => {
  const http = axios.create({
    baseURL: BASE_API_URL,
  });

  http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return http;
};

class ApiService {
  private axiosInstance = getAxiosInstance();

  public sections = {
    list: async (): Promise<AxiosResponse<{ }>> => (
      this.axiosInstance.get('/v1/products-sections')
    ),
  };

  public products = {
    listByType: async (type: string): Promise<AxiosResponse<{ }>> => (
      this.axiosInstance.get(`/v1/products?type=${type}`)
    ),
    getOneById: async (id: string): Promise<Product> => {
      const { data } = await this.axiosInstance.get(`/v1/products/${id}`);

      return data;
    },
  };

  public carts = {
    create: async (payload: any): Promise<Cart> => {
      const { data } = await this.axiosInstance.post('/v1/carts', payload);

      return CartTransformer.transform(data);
    },
    delete: async (id: string): Promise<void> => {
      await this.axiosInstance.delete(`/v1/carts/${id}`);
    },
    replace: async (id: string, payload: any): Promise<Cart> => {
      const { data } = await this.axiosInstance.put(`/v1/carts/${id}`, payload);

      return CartTransformer.transform(data);
    },
  };

  public contact = {
    create: async (payload: any): Promise<AxiosResponse<void>> => (
      this.axiosInstance.post('/v1/contact', payload)
    ),
  };

  public orders = {
    getPaymentData: async (orderId: string, app?: string): Promise<Payment> => {
      const { data } = await this.axiosInstance.get(`/v1/orders/${orderId}/payments-data/@payment-type?app=${app}`);

      return data;
    },
    create: async (payload: any): Promise<Order> => {
      const { data } = await this.axiosInstance.post('/v1/orders', payload);

      return {
        id: data.id,
        number: data.number,
        total: data.total,
        customerEmail: data.customer_email,
        paymentType: data.payment_type,
        products: data.products ? data.products.map((product: any) => ({
          id: product.id,
          name: product.name,
          price: product.price,
          currency: product.currency,
          quantity: product.quantity,
        })) : [],
      };
    },
  };
}

export default new ApiService();
