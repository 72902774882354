import { ProductTranslations } from '../interfaces/product';

const PolishProductTranslations: ProductTranslations = {
  description: 'Opis',
  price: {
    vat: 'zawiera 23% vat',
  },
  attributes: {
    label: {
      link: 'Link:',
      putQuantity: 'Wpisz ilość:',
      quantity: 'Ilość:',
      comment: 'Komentarze (jeden komentarz w jednej linii):',
      username: 'Twoja Nazwa Użytkownika:',
    },
    placeholder: {
      comment: 'Wpisz komentarz. Następnie naciśnij ENTER (minimalna ilość {{min}})',
      username: 'Np. selenagomez ',
    },
  },
  buttons: {
    addToCart: 'Dodaj do koszyka',
    addComment: 'Dodaj',
    more: 'Więcej...',
    less: 'Mniej',
  },
};

export default PolishProductTranslations;
