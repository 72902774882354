import React, { FC, useCallback } from 'react';
import {
  Button,
  Col,
  Layout,
  Row,
  Typography,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import classes from './CartSideList.module.less';
import { getCartItems, isOpenCartList } from '../../views/cart/slice/selectors';
import { displayPrice } from '../../common/currency';
import {
  removeProductToCart, toggleOpenCartList, increaseCartItem, decreaseCartItem,
} from '../../views/cart/slice/actions';
import CartSideSummary from '../CartSideSummary';
import Path from '../../constants/Path';
import Picture from '../Picture';
import QuantityButton from '../QuantityButton';
import { CartItem } from '../../types';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;

const CartSideList: FC<any> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = history;
  const items = useSelector(getCartItems);
  const isOpen = useSelector(isOpenCartList);

  const toggleCartListOpen = useCallback(() => dispatch(toggleOpenCartList()), [dispatch]);
  const removeItemFromCart = useCallback((item) => dispatch(removeProductToCart(item)), [dispatch]);
  const increase = useCallback((item: CartItem) => dispatch(increaseCartItem(item)), [dispatch]);
  const decrease = useCallback((item: CartItem) => dispatch(decreaseCartItem(item)), [dispatch]);

  return (
    <Sider
      className={classes.sideList}
      width={isOpen ? '333px' : 0}
    >
      <div className={classes.sideListHeader}>
        <Title level={3} className={classes.sideListHeaderTitle}>
          {t('common:cart.name')}
        </Title>
        <div
          tabIndex={-1}
          role="button"
          onKeyDown={toggleCartListOpen}
          onClick={toggleCartListOpen}
          className={classes.sideListHeaderButton}
        >
          <CloseOutlined />
        </div>
      </div>
      <div className={classes.itemList}>
        {
          items.map((item: CartItem) => (
            <div key={`${item.productId}-${item.attributes.link}`}>
              <Row gutter={32} justify="center" className={classes.previewItem}>
                <Col span={10} className={classes.pictureContainer}>
                  <Picture className={classes.previewItemImage} photoUrl={item.pictureUrl} alt={item.name} />
                </Col>
                <Col span={14}>

                  <Title level={3}>
                    {item.name}
                  </Title>
                  <Paragraph className={classes.typeName}>
                    {item.type}
                  </Paragraph>
                  <Paragraph className={classes.attribute}>
                    {t('common:link')} {item.attributes?.link}
                  </Paragraph>
                </Col>
              </Row>
              <Row gutter={16} justify="center" className={classes.previewAction}>
                <Col
                  span={5}
                  className={classNames([
                    classes.right,
                    classes.actionsContainer,
                  ])}
                >
                  <Button
                    className={classes.linkButton}
                    type="link"
                    onClick={() => removeItemFromCart(item)}
                  >
                    {t('common:remove')}
                  </Button>
                </Col>
                <Col span={12} className={classes.center}>
                  <QuantityButton
                    disabled={!!item.metadata?.comments}
                    quantity={item.quantity}
                    min={item.minQuantity}
                    max={item.maxQuantity}
                    decrease={() => decrease(item)}
                    increase={() => increase(item)}
                  />
                </Col>
                <Col span={7} className={classNames([classes.right, classes.flex])}>
                  {displayPrice(item.price, item.quantity)}
                </Col>
              </Row>
            </div>
          ))
        }
      </div>
      <CartSideSummary>
        <Button
          type="primary"
          size="large"
          block
          onClick={() => push(Path.CART_SUMMARY)}
        >
          {t('common:cart.toCart')}
        </Button>
      </CartSideSummary>
    </Sider>
  );
};

export default withRouter(CartSideList);
