/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  isLoading: boolean,
  product: any, // TODO change type
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  isLoading: true,
  product: null,
};

const { name, reducer, ...rest } = createSlice({
  name: 'product',
  initialState: INITIAL_STATE,
  reducers: {
    startFetchProductData: (state) => {
      state.isLoading = true;
      state.product = null;
    },
    setProductData: (state, action: { payload: any }) => {
      state.product = action.payload;
    },
    finishFetchProductData: (state) => {
      state.isLoading = false;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
