import React, { FC } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classes from './QuantityButton.module.less';

type QuantityButtonProps = {
  quantity: number,
  decrease: (event: React.MouseEvent<HTMLButtonElement>) => void,
  increase: (event: React.MouseEvent<HTMLButtonElement>) => void,
  min?: number
  max?: number,
  disabled?: boolean,
};

const QuantityButton: FC<QuantityButtonProps> = ({
  quantity = 1,
  decrease,
  increase,
  min = 50,
  max,
  disabled = false,
}) => (
  <>
    <Button
      className={classNames([
        classes.button,
      ])}
      shape="circle"
      icon={<MinusOutlined />}
      onClick={decrease}
      disabled={quantity <= min || disabled}
    />
    <span className={classes.quantity}>{quantity}</span>
    <Button
      className={classNames([
        classes.button,
      ])}
      shape="circle"
      icon={<PlusOutlined />}
      onClick={increase}
      disabled={(max && quantity >= max) || disabled}
    />
  </>
);

export default QuantityButton;
