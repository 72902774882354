import {
  FLUSH, PAUSE, PERSIST,
  persistStore, PURGE, REGISTER, REHYDRATE,
} from 'redux-persist';
import {
  configureStore, Action, Slice, getDefaultMiddleware, combineReducers,
} from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import HomeSlice from '../views/home/slice/slice';
import ProductListSlice from '../views/products-list/slice/slice';
import ProductSlice from '../views/product/slice/slice';
import CartSlice from '../views/cart/slice/slice';
import ContactSlice from '../views/contact/slice/slice';

export const mapSliceToReducers = (slices: Slice[]) => slices
  .map((slice) => ({ [slice.name]: slice.reducer }))
  .reduce((previous, current) => ({ ...previous, ...current }), {});

const reducer = combineReducers(mapSliceToReducers(
  [
    HomeSlice,
    ProductSlice,
    ProductListSlice,
    CartSlice,
    ContactSlice,
  ],
));

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof reducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;
export type AppDispatch = ThunkDispatch<RootState, null, Action<string>>;
