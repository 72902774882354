/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  isLoading: boolean,
  products: any[], // TODO change type
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  isLoading: true,
  products: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
};

const { name, reducer, ...rest } = createSlice({
  name: 'products-list',
  initialState: INITIAL_STATE,
  reducers: {
    startFetchListData: (state) => {
      state.isLoading = true;
      state.products = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]; // Add fake products
    },
    setProductItems: (state, action: { payload: any }) => {
      const { items } = action.payload;
      state.products = items;
    },
    finishFetchListData: (state) => {
      state.isLoading = false;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
