import i18n from 'i18next';
import slice from './slice';
import API from '../../../services/ApiService';
import { AppThunk } from '../../../redux/store';
import { getCartItemsData } from './selectors';
import history from '../../../getBrowserHistory';
import { CartItem, CartItemDataApi } from '../../../types';
import Path from '../../../constants/Path';
import { error, success } from '../../../components/Message';

const {
  setLoading,
  setPayment,
  saveOrder,
  clearCart,
  toggleOpenCartList,
  updateCartData,
} = slice.actions;

const addProductToCart = (payload: CartItemDataApi): AppThunk => (
  async (dispatch, getState) => {
    const state = getState();
    const { cart } = state;
    const cartItems = getCartItemsData(state);

    const itemExist = cartItems.find((itemInCart: any) => (
      itemInCart.product_id === payload.product_id
        && itemInCart.link === payload?.link
    ));

    let newItems = [
      ...cartItems,
      payload,
    ];

    if (itemExist) {
      newItems = cartItems.map((itemInCart: any) => {
        const newItemValue = { ...itemInCart, metadata: { ...(itemInCart.metadata || {}) } };
        if (itemInCart.product_id === itemExist.product_id && itemInCart.link === itemExist.link) {
          newItemValue.quantity += payload.quantity;

          if (newItemValue.metadata?.comments || payload.metadata?.comments) {
            newItemValue.metadata.comments = [
              ...(newItemValue.metadata?.comments || []),
              ...(payload.metadata?.comments || []),
            ];
          }
        }

        return newItemValue;
      });
    }

    const request = {
      payment_type: cart.paymentType ? cart.paymentType : undefined,
      customer_email: cart.customerEmail ? cart.customerEmail : undefined,
      products: newItems,
    };

    try {
      let cartData: any;
      if (cart.id) {
        cartData = await API.carts.replace(cart.id, request);
      } else {
        cartData = await API.carts.create(request);
      }

      dispatch(updateCartData(cartData));
      success(i18n.t('cart:messages.addToCartSuccess'));
    } catch (e) {
      if (e.response.status === 404) {
        dispatch(clearCart());
      }

      error(i18n.t('cart:messages.addToCartError'));
    }
  }
);

const removeProductToCart = (payload: CartItem): AppThunk => (
  async (dispatch, getState) => {
    const state = getState();
    const { cart } = state;
    const cartItems = getCartItemsData(state);

    const request = {
      payment_type: cart.paymentType ? cart.paymentType : undefined,
      customer_email: cart.customerEmail ? cart.customerEmail : undefined,
      products: cartItems.filter((itemInCart: any) => !(
        itemInCart.product_id === payload.productId
        && itemInCart.link === payload.attributes.link
      )),
    };

    try {
      let cartData: any;
      if (cart.id) {
        cartData = await API.carts.replace(cart.id, request);
      } else {
        cartData = await API.carts.create(request);
      }

      dispatch(updateCartData(cartData));
      success(i18n.t('cart:messages.removeFromCartSuccess'));
    } catch (e) {
      if (e.response.status === 404) {
        dispatch(clearCart());
      }

      success(i18n.t('cart:messages.removeFromCartError'));
    }
  }
);

const increaseCartItem = (payload: CartItem): AppThunk => (
  async (dispatch, getState) => {
    const state = getState();
    const { cart } = state;
    const cartItems = getCartItemsData(state);

    const request = {
      payment_type: cart.paymentType ? cart.paymentType : undefined,
      customer_email: cart.customerEmail ? cart.customerEmail : undefined,
      products: cartItems.map((itemInCart: any) => {
        if (
          itemInCart.product_id === payload.productId
            && itemInCart.link === payload.attributes.link
        ) {
          let newQuantity = payload.quantity + 100;

          if (payload.maxQuantity && newQuantity > payload.maxQuantity) {
            newQuantity = payload.maxQuantity;
          }
          // eslint-disable-next-line no-param-reassign
          itemInCart.quantity = newQuantity;
        }

        return itemInCart;
      }),
    };

    try {
      let cartData: any;
      if (cart.id) {
        cartData = await API.carts.replace(cart.id, request);
      } else {
        cartData = await API.carts.create(request);
      }

      dispatch(updateCartData(cartData));
    } catch (e) {
      if (e.response.status === 404) {
        dispatch(clearCart());
      }

      // TODO fetch error
    }
  }
);

const decreaseCartItem = (payload: CartItem): AppThunk => (
  async (dispatch, getState) => {
    const state = getState();
    const { cart } = state;
    const cartItems = getCartItemsData(state);

    const request = {
      payment_type: cart.paymentType ? cart.paymentType : undefined,
      customer_email: cart.customerEmail ? cart.customerEmail : undefined,
      products: cartItems.map((itemInCart: any) => {
        if (
          itemInCart.product_id === payload.productId
            && itemInCart.link === payload.attributes.link
        ) {
          let newQuantity = payload.quantity - 100;

          if (payload.minQuantity && newQuantity < payload.minQuantity) {
            newQuantity = payload.minQuantity;
          }
          // eslint-disable-next-line no-param-reassign
          itemInCart.quantity = newQuantity;
        }

        return itemInCart;
      }),
    };

    try {
      let cartData: any;
      if (cart.id) {
        cartData = await API.carts.replace(cart.id, request);
      } else {
        cartData = await API.carts.create(request);
      }

      dispatch(updateCartData(cartData));
    } catch (e) {
      if (e.response.status === 404) {
        dispatch(clearCart());
      }

      // TODO fetch error
    }
  }
);

const createOrder = (payload: { email: string }): AppThunk => (
  async (dispatch, getState) => {
    const state = getState();
    const { cart } = state;
    const cartItems = getCartItemsData(state);

    const request = {
      source: process.env.REACT_APP_LOADING_PAGE ? 'web_instaup' : 'web',
      payment_type: cart.paymentType,
      customer_email: payload.email,
      products: cartItems,
    };

    try {
      const order = await API.orders.create(request);
      await API.carts.delete(cart.id);

      dispatch(clearCart());
      dispatch(saveOrder(order));
      history.push(Path.CART_PAYMENTS);
    } catch (e) {
      // TODO fetch error
    }
  }
);

const getPaymentData = (payload: { orderId: string }): AppThunk => (
  async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const payment = await API.orders.getPaymentData(
        payload.orderId,
        process.env.REACT_APP_LOADING_PAGE ? 'instaup' : 'wz',
      );

      dispatch(setPayment(payment));
      dispatch(setLoading(false));
    } catch (e) {
      // TODO fetch error
    }
  }
);

export {
  addProductToCart,
  getPaymentData,
  removeProductToCart,
  increaseCartItem,
  decreaseCartItem,
  createOrder,
  toggleOpenCartList,
};
