/* eslint-disable max-len */
import { CommonTranslations } from '../interfaces/common';

const PolishCommonTranslations: CommonTranslations = {
  home: 'Strona główna',
  cart: {
    name: 'Koszyk',
    total: 'Razem',
    toCart: 'Przejdź do koszyka',
  },
  remove: 'USUŃ',
  more: 'Zobacz wszystkie produkty...',
  for: 'za',
  price: 'Cena:',
  pcs: 'szt.',
  quantity: 'Ilość',
  link: 'Link:',
  from: 'Od',
  delete: 'Usuń',
  validation: {
    required: 'Pole jest wymagane',
    url: 'Link ma niepoprawny format',
    min: 'Minimalna wartość to {{number}}',
    minmax: 'Wartość musi mieścić się przedziale od {{min}} do {{max}}',
    termsAccept: {
      required: 'Regulamin musi zostać zakceptowany',
    },
    email: {
      invalid: 'Nieprawidłowy format',
    },
  },
  company: {
    name: 'Wyższe zasięgi',
  },
  type: {
    instagram: 'Instagram',
    facebook: 'Facebook',
    tiktok: 'Tiktok',
    youtube: 'Youtube',
    twitch: 'Twitch',
    twitter: 'Twitter',
  },
  footer: {
    copyright: '© {{year}} Wyższe zasięgi. Wszelkie prawa zastrzeżone.',
    termsAndConditions: 'Regulamin',
    privacyPolicy: 'Polityka prywatności',
    contact: 'Kontakt',
    api: 'API',
    headers: {
      important: 'Ważne linki',
      services: 'Lista usług',
    },
  },
  contact: {
    title: 'Skontaktuj się z nami',
    headquarters: 'Siedziba:',
    email: 'Email: ',
    phone: 'Telefon: ',
    send: 'Wyślij',
    successMessage: 'Twoja wiadomość została wysłana',
    errorMessage: 'Nie udało się wysłać wiadomości. Spróbuj ponownie później.',
    validation: {
      required: 'Pole jest wymagane',
      email: 'Nieprawidłowy format adresu e-mail',
    },
    placeholders: {
      email: 'Twój email',
      message: 'Napisz swoją wiadomość',
    },
  },
  terms: {
    title: 'Regulamin',
    parts: {
      1: {
        title: '1. Postanowienia ogólne',
        content: `<div><p>Stronami umowy sprzedaży poprzez witrynę znajdującą się pod adresem URL: <a href="https://www.instaup.com.pl">www.instaup.com.pl</a> są:</p>
    <p>Spółka Mastermarketing A&K LTD z siedzibą w Londynie (adres siedziby i adres do korespondencji: 27 Old Gloucester Street
     WC1N 3AX London, United Kingdom) wpisana do Rejestru pod numerem VAT ID: 2417601018 zwany dalej Sprzedającym oraz każda
      osoba fizyczna posiadającą pełną zdolność do czynności prawnych (konsument), osoba prawna lub jednostka organizacyjna
       nie posiadająca osobowości prawnej zwana Użytkownikiem.
    </p>
    <p>Sprzedaż odbywa się za pośrednictwem Internetu w formie umowy zawieranej na odległość, pomiędzy składającym zamówienie, a Sprzedającym.
    </p>
    <p>Akceptacja Regulaminu jest dobrowolna, ale konieczna w celu złożenia przez Kupującego Zamówienia.
    </p>
    <p>
    Nieznajomość Regulaminu nie zwalnia Użytkownika z przestrzegania zawartych w nim zasad.
    </p>
    <p>
    Podczas dokonywania zakupów w sklepie internetowym Użytkownik potwierdza fakt zapoznania się z niniejszym regulaminem
     i potwierdza, że akceptuje go w obowiązującej formie. Zobowiązany jest również podać dane kontaktowe, umożliwiające
      prawidłową realizację zamówienia (imię, nazwisko, adres e-mail, numer telefonu, potrzebne linki do realizacji zamówienia).
    </p>
    <p>
    Produkty dostępne w Sklepie są wolne od wad fizycznych i prawnych oraz są usługami świadczonymi drogą elektroniczną.
    </p>
    <div>`,
      },
      2: {
        title: '2. Definicje:',
        content: `<p>
    Serwis – serwis internetowy dostępny pod adresem <a href="https://www.instaup.com.pl">www.instaup.com.pl</a>, oraz wszystkie informację dostępne na stronie.
    Konsument/Kupujący – osoba fizyczna, zawierająca ze Sprzedawcą umowę sprzedaży niezwiązaną bezpośrednio z jej działalnością gospodarczą lub zawodową.
    Administracja Serwisu/Sprzedawca – Osoby/a odpowiedzialne/a za aktualizację i utrzymywanie serwisu.
    Treść Serwisu – Informacje i zarządzenia ogólnodostępne na stronie serwisu.<br />
    Użytkownik – Osoba odwiedzająca stronę lub osoba rejestrująca się w celu otrzymania informacji handlowych lub darmowych treści,
     a także osoba, która dokonuje zakupu przedmiotu wymienionego w ofercie witryny.<br />
    Administrator Danych – osoba fizyczna przetwarzająca dane osobowe w związku z działalnością zarobkową, zawodową.
    Produkt – rzecz ruchoma, którą Kupujący może zakupić w Sklepie lub treść cyfrowa w rozumieniu w ustawy z dnia 30 maja 2014 r.
     o prawach konsumenta, którą Kupujący może nabyć od Sprzedawcy za pośrednictwem Sklepu.<br />
     Zamówienie – oświadczenie woli Kupującego składane za pomocą formularza zamówienia i zmierzające bezpośrednio do zawarcia umowy sprzedaży Produktu lub Produktów ze Sprzedawcą.<br />
    Regulamin – niniejszy regulamin korzystania z Serwisu.<br />
    Zasięgi - usługi oferowane w sklepie
    </p>`,
      },
      3: {
        title: '3. Produkt sprzedaży / Usługi świadczone drogą elektroniczną:',
        content: `<div>
    <p>
    Za pośrednictwem Sklepu www.instaup.com.pl Sprzedawca świadczy na rzecz Kupującego następujące usługi: Umożliwienie składania Zamówień, Koszyk.
    </p>
    <p>
    Przedmiotem sprzedaży są umieszczone w Serwisie Zasięgi (dedykowane dla IG,FB,TT,YT).
    </p>
    <p>
    Zasięgi są dostarczane bezpośrednio tam, gdzie wskaże Kupujący w formularzu zamówienia.
    </p>
    </div>`,
      },
      4: {
        title: '4 . Zamówienia:',
        content: `<div>
    <p>Zamówienia można składać poprzez formularz dostępny na stronie internetowej Sklepu.
    Za kompletne złożenie zamówienia uważa się wypełnienie formularza na stronie Serwisu i dokonanie płatności na wskazany przez Sprzedającego numer rachunku bankowego.
    </p>
    <p>W celu złożenia zamówienia, Kupujący kompletuje Zamówienie poprzez wybranie Produktów w Sklepie. Następnie wybrane przez Kupującego Produkty zostają przeniesione do koszyka. Po skompletowaniu Zamówienia, Kupujący powinien postępować zgodnie z instrukcją pojawiającą się na jego ekranie, tj. wybrać adres dostawy (adres e-mail) oraz sposób płatności i kliknąć ikonę „Potwierdź zamówienie i zapłać”. Składając Zamówienie Kupujący zobowiązuje się do zapłaty ceny wybranych pozycji oraz odbioru zamówionych Produktów.
    </p>
    <p>Każde zamówienie weryfikowane jest w sposób automatyczny, poprzez wysłanie wiadomości email na adres podany przez Użytkownika przy składaniu zamówienia. Otrzymanie wiadomości email informującej o pozytywnym statusie przyjęcia zamówienia oznacza, że zamówienie zostało złożone prawidłowo.
    </p>
    <p>Sprzedający przewiduje możliwość umieszczenia w Serwisie promocyjnych ofert sprzedaży produktów. Promocyjne oferty sprzedaży wystawiane są okresowo, ilość i asortyment produktów w sprzedaży promocyjnej zależna jest od Sprzedającego. Liczba produktów w sprzedaży promocyjnej jest ograniczona.
    </p>
    <p>Podane przy opisach poszczególnych Produktów ceny są wyrażone w złotych polskich (PLN) i obejmują wszystkie ich składniki (VAT, podatki). Ceny produktów nie zawierają kosztów przesyłki. Zmiana ceny następuje poprzez publikację na stronie Sklepu nowych ofert cenowych i wiąże Sklep od chwili ich publikacji oraz nie ma wpływu na realizację umów zawartych w wyniku złożonych wcześniej Zamówień.
    </p>
    <p>Sprzedający realizuje zamówienie niezwłocznie, nie później niż 12 godzin od otrzymania zamówienia. Zastrzega jednak, że możliwe są opóźnienia w realizacji Zamówienia.
    </p>
              </div>`,
      },
      5: {
        title: '5. Płatności i wysyłka',
        content: `<div>
    <p>
    W witrynie <a href="https://www.instaup.com.pl">www.instaup.com.pl</a>, masz możliwość wyboru spośród wielu metod płatności.
    </p>
    <p>
    Szybkie płatności internetowe: płatność za zamówiony produkt dokonuje się za pośrednictwem serwisu DotPay.
    </p>
    <img alt="DotPay" src="https://api.wyzszezasiegi.pl/media/common/dotpay1.png" style="display: block; object-fit: cover;width: 100%;max-width: 600px;" loading="lazy" />
    <p>
    <br />
    Szybkie Transfery – W serwisie Dotpay można dokonać transakcji następującymi przelewami transferowymi:
    </p>
    <p>
    1) E-przelewy
    </p>
    <p>
    2) Płatności kartami Płatniczymi: Dokonując płatności w serwisie Dotpay, mają Państwo możliwość 
    wyboru płatności kartami płatniczymi (karty debetowe, karty kredytowe, karty obciążeniowe, karty przepłacone)
    </p>
    <p>
    3) Płatności gotówkowe: Dzięki takiemu rozwiązaniu, kupujący może np. wygenerować pocztowy druk przelewu
     i opłacić go w dowolnej placówce Poczty/banku. W skład płatności gotówkowych oferowanych przez Dotpay wchodzą 
     m.in.:(Przelew/Przekaz, Zapłać w Żabce i we Freshmarket, VIA – Moje Rachunki, Kantor Polski).
    </p>
    <img alt="DotPay" src="https://api.wyzszezasiegi.pl/media/common/dotpay2.png" style="display: block; object-fit: cover;width: 100%;max-width: 399px;" loading="lazy" />
    <p>
    <br />
    Za datę otrzymania zapłaty uważa się zaksięgowanie na rachunku Sprzedającego wpłaty odpowiadającej wysokości cen towaru.
    Kupujący, przed dokonaniem płatności za pośrednictwem zewnętrznego usługodawcy, zobowiązany jest zapoznać się
     z regulaminem świadczenia usług przez zewnętrznego usługodawcę.
    </p>
    <p>
    Dokonując zakupów w Serwisie <a href="https://www.instaup.com.pl">www.instaup.com.pl</a>, nie ponosisz kosztów wysyłki. Dostarczenie produktów cyfrowych odbywa się poprzez internet i jest bezpłatne.
    Po otrzymaniu informacji o dokonanej płatności, Sprzedawca wysyła na adres e-mail podany przez Kupującego informacje o otrzymaniu płatnośc.
    </p>
    </div>`,
      },
      6: {
        title: '6. Odstąpienie od umowy:',
        content: `<div>
    <p>
    Zamówienie realizuje się automatycznie po opłaceniu zamówienia, poprzez przesłanie zamówienia
    do systemu, który realizuje chronologicznie dobicie odpowiednich zasięgów.
    </p>
    <p>
    W związku z tym na podstawie art. 38 ust. 1 Ustawy o prawach konsumenta z dnia 30 maja 2014 r.,
     Klientowi nie przysługuje prawo do odstąpienia od umowy po opłaceniu zamówienia. Wszelkie wnioski
      dotyczącego ewentualnego odstąpienia od umowy rozpatrywane są indywidualnie w związku ze złożoną
       prośbą o odstąpienie od umowy. Decyzja o przyznaniu prawa do odstąpienia od umowy podejmowana
        jest po analizie kosztów poniesionych przez Sprzedającego do momentu złożenia wniosku oraz
         późniejszych kosztów, których Sprzedający nie może uniknąć w związku z Zamówieniem w stosunku
          do którego złożony został wniosek.
    </p>
             </div>`,
      },
      7: {
        title: '7. Reklamacje:',
        content: `<div>
    <p>
    W przypadku ewentualnych reklamacji dotyczących działania Serwisu, Kupujący może zgłaszać je za pośrednictwem poczty
     elektronicznej na adres: <a href="mailto:kontakt@wyzszezasiegi.pl">kontakt@wyzszezasiegi.pl</a>.
    Kupujący powinien podać swoje imię i nazwisko, datę otrzymanej wiadomości e-mail, potwierdzającej realizację zamówienia, dokładny opis
     i powód reklamacji a także zatytułować wiadomość: „Reklamacja nr. zamówienia”.
    </p>
    <p>
    Sprzedawca będzie rozpatrywać wszelkie reklamacje w terminie do 14 dni od otrzymania reklamacji i
     poinformuje Kupującego o jej rozstrzygnięciu na adres poczty e-mail składającego reklamację.
    </p>
    </div>`,
      },
      8: {
        title: '8. Dane osobowe i pliki cookies:',
        content: `<div>
    <p>
    Dostępne w zakładce Polityka Prywatności pod linkiem:
    </p>
    <a href="https://www.instaup.com.pl/privacy-policy">Polityka Prywatności</a>
    </div>`,
      },
      9: {
        title: '9. Postanowienia końcowe:',
        content: `<div>
    <p>Sprzedawca zastrzega sobie prawo do wprowadzania zmian cen produktów znajdujących się w ofercie,
     odwoływania ofert, występujących promocji oraz do zmian cen produktów w Sklepie bez uszczerbku dla
      praw nabytych przez Kupującego, w tym warunków umów zawartych przed dokonaniem zmian.
    Sprzedawca zastrzega sobie możliwość wprowadzania zmian w Regulaminie. Do umów zawartych przed zmianą
     Regulaminu stosuje się Regulamin obowiązujący w momencie zawarcia umowy.
    </p>
   
    </div>`,
      },
      10: {
        title: '10. Faktury za zamówienie:',
        content: `<div>
    <p>
     W przypadku chęci otrzymania faktury, prosimy o zgłoszenie takowej informacji przed dokonaniem zakupu na nasz adres e-mail <a href="mailto:kontakt@wyzszezasiegi.pl">kontakt@wyzszezasiegi.pl</a>.
     Faktura za złożone zamówienie będzie podesłana drogą elektroniczną na wskazany przez Państwa adres e-mail.
    </p>
    <p>
    W sprawach nie uregulowanych niniejszym regulaminem zastosowanie mają przepisy Kodeksu Cywilnego,
     ustawy z dnia 02.03.2000 r. o ochronie niektórych praw konsumentów oraz o odpowiedzialności za
      szkodę wyrządzoną przez produkt niebezpieczny oraz ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.
    Administrator Serwisu zastrzega sobie prawo do anonimowej publikacji treści kierowanych przez Użytkowników,
     dotyczących kwestii związanych z funkcjonowaniem Serwisu, udzielanymi poradami i innych, co do których
      Administracja Serwisu uzna, że odpowiedzi na te treści warte są opublikowania, w celu pomocy innym.
    Niniejszy Regulamin obowiązuje od dnia 20.09.21 r.
    </p>
    <p>
    Niniejszy Regulamin jest udostępniony nieodpłatnie Kupującemu, na rzecz zawarcia umowy o 
    świadczenie usług drogą elektroniczną oraz umów sprzedaży. Kupujący ma możliwość w każdej
     chwili jego pozyskania i ponownego odtwarzania.
    </p>
    <p>
    Zamieszczony regulamin jest chroniony prawem autorskim. Zabronione jest jego kopiowanie
     w całości lub fragmentach bez pisemnej zgody Administratora witryny.
    </p>
    <p>
    Zespół<br />
    <a href="https://www.instaup.com.pl">www.instaup.com.pl</a>
    </p>
    </div>`,
      },
    },
  },
  privacyPolicy: {
    title: 'Polityka prywatności',
    parts: {
      general: {
        title: 'KLAUZULA INFORMACYJNA O PRZETWARZANIU DANYCH OSOBOWYCH',
        content: 'Szanowny Kliencie zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r.'
            + ' w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich'
            + ' danych oraz uchylenia dyrektywy 95/46/WE ( dalej jako: “RODO” ) stosowanego od 25 maja 2018 r. informujemy, że'
            + ' Informacje podstawowe dotyczące przetwarzania danych osobowych:',
      },
      1: {
        title: '1. Administrator danych osobowych',
        content: 'Administratorem Twoich danych osobowych jest MASTERMARKETING A&K LTD z siedzibą w United Kingdom Company number: 11388452 zwany dalej Administratorem.',
      },
      2: {
        title: '2. Cele i podstawy przetwarzania',
        content: 'Twoje dane osobowe będą przetwarzane przez Administratora w szczególności: w celu podjęcia działań przed'
            + ' zawarciem umowy na żądanie osoby, której dane dotyczą lub wykonania umowy, której stroną jest osoba, której'
            + ' dane dotyczą (dane konieczne do zawarcia umowy wskazane są w formularzu kontaktowym) – (podstawa z art. 6 ust. 1 lit. c RODO);'
            + ' w celu zawarcia umowy sprzedaży, na podstawie Twojego zainteresowania naszą ofertą (podstawa z art. 6 ust. 1 lit. b RODO);'
            + ' w celach analitycznych [lepszego doboru usług do potrzeb naszych klientów, ogólnej optymalizacji naszych produktów,'
            + ' optymalizacji procesów obsługi, budowania wiedzy o naszych klientach, analizy finansowej naszej firmy itp.] będącego'
            + ' realizacją naszego prawnie uzasadnionego w tym interesu (podstawa z art. 6 ust. 1 lit. f RODO); w celach archiwalnych '
            + '(dowodowych) będących realizacją naszego prawnie uzasadnionego interesu zabezpieczenia informacji na wypadek prawnej'
            + ' potrzeby wykazania faktów (art. 6 ust. 1 lit. f RODO); w celu ewentualnego ustalenia, dochodzenia lub obrony przed '
            + 'roszczeniami będącego realizacją naszego prawnie uzasadnionego w tym interesu (podstawa z art. 6 ust. 1 lit. f RODO);'
            + ' w celu badania satysfakcji klientów będącego realizacją naszego prawnie uzasadnionego interesu określania jakości'
            + ' naszej obsługi oraz poziomu zadowolenia naszych klientów z produktów i usług (podstawa z art. 6 ust. 1 lit. f RODO);'
            + ' w celu oferowania Ci przez nas produktów i usług bezpośrednio (marketing bezpośredni), w tym dobierania ich pod kątem '
            + 'Twoich potrzeb, czyli profilowania, będącego realizacją naszego prawnie uzasadnionego w tym interesu (podstawa z art. 6 ust. 1 lit. f RODO);'
            + ' Podstawą prawną przetwarzania Twoich danych jest umowa pomiędzy Tobą a Administratorem, zwana dalej: „Umową”, do'
            + ' zawarcia której dochodzi wskutek akceptacji Regulaminu i dla wykonania której przetwarzanie Twoich danych jest '
            + 'niezbędne lub w przypadku lub udzielona przez Ciebie odrębna „Zgoda” np. w celu wysłania Tobie informacji handlowych'
            + ' tzw. newsletter lub stosowania przez nas marketingu bezpośredniego. Podanie danych osobowych w przypadku chęci'
            + ' zawarcia Umowy nie jest obowiązkowe, jednakże ich niepodanie spowoduje, że zawarcie i realizacja Umowy będą niemożliwe.',
      },
      3: {
        title: '3. Okres przechowywania danych',
        content: 'Twoje dane osobowe przetwarzane w celu zawarcia lub wykonania umowy będą przechowywane nie dłużej niż jest to'
            + ' konieczne, tj. przez okres niezbędny do: po sprzedażowej obsługi Klientów (np. obsługi reklamacji), zabezpieczenia'
            + ' lub dochodzenie ewentualnych roszczeń, wypełnienia obowiązku prawnego Administratora (np. wynikających z przepisów'
            + ' rachunkowych lub podatkowych), Twoje dane osobowe przetwarzane na potrzeby marketingu produktów własnych Administratora'
            + ' na podstawie uzasadnionego interesu prawnego, będą przetwarzane do czasu zgłoszenia sprzeciwu przez osobę, której dane'
            + ' dotyczą. Twoje dane osobowe przetwarzana na podstawie odrębnej zgody będą przetwarzane do czas jej odwołania.',
      },
      4: {
        title: '4. Podstawy prawne podjęcia działań',
        content: 'Przetwarzanie jest niezbędne do wykonania umowy lub do podjęcia działań na żądanie osoby, której dane dotyczą,'
            + ' przed zawarciem umowy, uzasadniony interes Administratora,',
      },
      5: {
        title: '5. Odbiorcy danych',
        content: 'Podmioty przetwarzające dane w imieniu Administratora.',
      },
      6: {
        title: '6. Prawo do sprzeciwu',
        content: 'W każdej chwili przysługuje Ci prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych opisanych powyżej.'
            + ' Przestaniemy przetwarzać Twoje dane w tych celach, chyba że będziemy w stanie wykazać, że w stosunku do Twoich danych'
            + ' istnieją dla nas ważne prawnie uzasadnione podstawy, które są nadrzędne wobec Twoich interesów, praw i wolności lub'
            + ' Twoje dane będą nam niezbędne do ewentualnego ustalenia, dochodzenia lub obrony roszczeń. W każdej chwili przysługuje'
            + ' Ci prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych w celu prowadzenia marketingu bezpośredniego.'
            + ' Jeżeli skorzystasz z tego prawa – zaprzestaniemy przetwarzania danych w tym celu.',
      },
      7: {
        title: '7. Prawa osób, których dane dotyczą:',
        content: 'Zgodnie z RODO, przysługuje Ci:\n'
                  + 'a/ prawo dostępu do swoich danych oraz otrzymania ich kopii;\n'
                  + 'b/ prawo do sprostowania (poprawiania) swoich danych;\n'
                  + 'c/ prawo do usunięcia danych, ograniczenia przetwarzania danych;\n'
                  + 'd/ prawo do wniesienia sprzeciwu wobec przetwarzania danych;\n'
                  + 'e/ prawo do przenoszenia danych;\n'
                  + 'f/ prawo do cofnięcia zgody w dowolnym momencie.\n'
                  + 'g/ prawo do wniesienia skargi do organu nadzorczego.',
      },
      8: {
        title: '8. Zautomatyzowane podejmowanie decyzji',
        content: 'Twoje dane osobowe będą przetwarzane w sposób zautomatyzowany (w tym w formie profilowania), jednakże nie będzie'
            + ' to wywoływać wobec Ciebie żadnych skutków prawnych lub w podobny sposób istotnie wpływać na Twoją sytuację.'
            + ' Profilowanie danych osobowych przez nas polega na przetwarzaniu Twoich danych (również w sposób zautomatyzowany),'
            + ' poprzez wykorzystywanie ich do oceny niektórych informacji o Tobie, w szczególności do analizy lub prognozy osobistych preferencji oraz zainteresowań.\n',
      },
      9: {
        title: '9. Administrator Danych Osobowych',
        content: '<p>Pytania dotyczące ochrony danych w Master Marketing można w każdej chwili kierować do naszego działu ochrony danych pod adresem <a href="mailto:kontakt@wyzszezasiegi.pl">kontakt@wyzszezasiegi.pl</a>.</p>',
      },
      10: {
        title: '10. Pliki cookies',
        content: 'Administrator zbiera w sposób automatyczny dane zawarte w plikach cookies podczas samego korzystania z Witryny.'
            + ' Pliki cookies to małe pliki tekstowe wysyłane przez sklep internetowy i przechowywane na Państwa komputerze zawierające'
            + ' pewne informacje związane z korzystaniem przez Państwa z Witryny i Sklepu Internetowego. Wykorzystywane przez sklep'
            + ' internetowy pliki cookies mogą mieć charakter tymczasowy lub trwały. Tymczasowe pliki cookies są usuwane z chwilą'
            + ' zamknięcia przeglądarki, natomiast stałe pliki cookies są przechowywane także po zakończeniu korzystania przez Państwa'
            + ' z Witryny i służą do przechowywania informacji takich jak Państwa hasło czy login, co przyspiesza i ułatwia korzystanie'
            + ' z Witryny. Sklep internetowy wykorzystuje poniżej wymienione pliki cookie w następujących celach: utrzymanie sesji'
            + ' użytkownika Witryny (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Witryny ponownie wpisywać'
            + ' loginu i hasła, tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy Witryny korzystają ze stron'
            + ' internetowych, co umożliwia ulepszanie ich struktury i zawartości, wyświetlanie dostosowanych do użytkownika reklam'
            + ' remarketingowych. W każdym wypadku mogą Państwo zablokować instalowanie plików cookies lub usunąć stałe pliki cookies,'
            + ' wykorzystując stosowne opcje Państwa przeglądarki internetowej. W razie problemów doradzamy skorzystać z pliku pomocy '
            + 'przeglądarki lub skontaktować się z producentem przeglądarki, z której Państwo korzystacie.\n'
                  + '<br/><br />10A. Więcej informacji na temat plików cookies dostępnych jest pod adresem <a href="http://www.wszystkoociasteczkach.pl">www.wszystkoociasteczkach.pl</a> lub w'
            + ' sekcji „Pomoc” w menu przeglądarki internetowej.\n',
      },
    },
  },
  notFound: {
    title: 'Nie znaleziono',
    subtitle: 'Podana strona nie istnieje',
    backHome: 'Wróć na stronę główną ',
  },
};

export default PolishCommonTranslations;
