/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */
import React, { FC, useCallback } from 'react';
import {
  Badge, Col, Layout, Menu, Row,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/logo.svg';
import logoInstaUp from '../../../assets/logo-instaup.png';
import cartIcons from '../../../assets/icons/cart';
import classes from './DefaultHeader.module.less';
import './DefaultHeader.less';
import Path, { getProductsListLink } from '../../../constants/Path';
import { getCartItemsCount } from '../../../views/cart/slice/selectors';
import { toggleOpenCartList } from '../../../views/cart/slice/actions';

const { Header } = Layout;
const DefaultHeader: FC<any> = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = history;
  const itemsCount = useSelector(getCartItemsCount);
  const cartIcon = cartIcons.main;

  const toggleCartListOpen = useCallback(() => dispatch(toggleOpenCartList()), [dispatch]);

  return (
    <Header className={classes.header}>
      <Row gutter={0} className={classes.wrapper}>
        <Col xs={18} sm={6} md={4} lg={3}>
          <img
            src={process.env.REACT_APP_LOADING_PAGE ? logoInstaUp : logo}
            className={classes.logo}
            alt={t('common:company.name')}
            title={t('common:company.name')}
            onClick={() => push(process.env.REACT_APP_LOADING_PAGE ? Path.SHOP : Path.HOME)}
            loading="lazy"
          />
        </Col>
        <Col xs={4} sm={16} md={18} lg={19} className={classes.container}>
          <Menu
            className={classes.menu}
            mode="horizontal"
            overflowedIndicator={<MenuOutlined />}
            subMenuOpenDelay={0}
            subMenuCloseDelay={0}
            triggerSubMenuAction="click"
            forceSubMenuRender
            selectable={false}
          >
            <Menu.Item
              key="instagram"
              className={classes.menuItem}
              onClick={() => push(getProductsListLink('instagram'))}
            >
              {t('common:type.instagram')}
            </Menu.Item>
            <Menu.Item
              key="youtube"
              className={classes.menuItem}
              onClick={() => push(getProductsListLink('youtube'))}
            >
              {t('common:type.youtube')}
            </Menu.Item>
            <Menu.Item
              key="facebook"
              className={classes.menuItem}
              onClick={() => push(getProductsListLink('facebook'))}
            >
              {t('common:type.facebook')}
            </Menu.Item>
            <Menu.Item
              key="tiktok"
              className={classes.menuItem}
              onClick={() => push(getProductsListLink('tiktok'))}
            >
              {t('common:type.tiktok')}
            </Menu.Item>
            <Menu.Item
              key="twitter"
              className={classes.menuItem}
              onClick={() => push(getProductsListLink('twitter'))}
            >
              {t('common:type.twitter')}
            </Menu.Item>
            <Menu.Item
              key="contact"
              className={classes.menuItem}
              onClick={() => push(Path.CONTACT)}
            >
              {t('common:footer.contact')}
            </Menu.Item>

            <Menu.Item
              key="api"
              className={classes.menuItem}
              onClick={() => push(Path.CONTACT)}
            >
              {t('common:footer.api')}
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <div
            tabIndex={-1}
            role="button"
            className={classes.cartButton}
            onKeyDown={toggleCartListOpen}
            onClick={toggleCartListOpen}
          >
            <Badge className={classes.badge} count={itemsCount}>
              <img src={cartIcon} alt="Cart" loading="lazy" />
            </Badge>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default withRouter(DefaultHeader);
