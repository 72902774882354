import React, { FC, useMemo } from 'react';
import { getPhotoSrc } from '../../common/photo';

type PictureProps = {
  className?: string;
  alt?: string;
  photoUrl: string
};

const Picture: FC<PictureProps> = ({ className, alt, photoUrl }) => {
  const photoSrc = useMemo(() => getPhotoSrc(photoUrl), [photoUrl]);

  return (
    <img
      className={className}
      src={photoSrc}
      alt={alt}
      loading="lazy"
    />
  );
};

export default Picture;
