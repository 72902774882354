/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  isLoading: boolean,
  sections: any[], // TODO change type
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  isLoading: true,
  sections: [
    {
      name: 'instagram',
      items: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
    },
    {
      name: 'youtube',
      items: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
    },
    {
      name: 'facebook',
      items: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
    },
    {
      name: 'tiktok',
      items: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
    },
  ],
};

const { name, reducer, ...rest } = createSlice({
  name: 'home',
  initialState: INITIAL_STATE,
  reducers: {
    startFetchSectionData: (state) => {
      state.isLoading = true;
    },
    setSectionsData: (state, action: { payload: any }) => {
      const { items } = action.payload;
      state.sections = items;
    },
    finishFetchSectionData: (state) => {
      state.isLoading = false;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
