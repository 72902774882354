import React from 'react';
import { Route } from 'react-router';
import FixWidthContent from '../components/FixWidthContent';
import NotFound from '../views/not-found';
import DefaultLayout from '../layouts/DefaultLayout';

const NotFoundRoute = (
  <Route path="*">
    <DefaultLayout>
      <FixWidthContent>
        <NotFound />
      </FixWidthContent>
    </DefaultLayout>
  </Route>
);

export default NotFoundRoute;
