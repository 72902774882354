import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import classes from './CartSideSummary.module.less';
import { displayPrice } from '../../common/currency';
import { getCartTotal } from '../../views/cart/slice/selectors';

const { Title } = Typography;

type CartSideSummaryProps = {
  title?: string;
  showSummary?: boolean;
};

const CartSideSummary: FC<CartSideSummaryProps> = ({
  title,
  children,
  showSummary = true,
}) => {
  const { t } = useTranslation();
  const orderTotal = useSelector(getCartTotal);

  return (
    <div className={classes.summary}>
      {showSummary && (
        <>
          {title && <Title className={classes.title} level={3}>{title}</Title>}
          <div className={classes.totalContainer}>
            <Title level={2} className={classes.total}>
              <span className={classes.totalHeader}>{t('common:cart.total')}:</span>
              <span>{displayPrice(orderTotal)}</span>
            </Title>
          </div>
        </>
      )}
      <div className={classes.actionButtons}>
        {children}
      </div>
    </div>
  );
};

export default CartSideSummary;
