/* eslint-disable max-len */
import { CartTranslations } from '../interfaces/cart';

const PolishCartTranslations: CartTranslations = {
  backToShop: 'Wróć do sklepu',
  continueShopping: 'Kontynuuj zakupy',
  proceedToCheckout: 'Przejdź dalej',
  proceedToPayment: 'Złóż zamówienie i zapłać',
  termsAccept: 'Akceptuję warunki <a href="https://wyzszezasiegi.pl/terms-and-conditions" style="color: blue">regulaminu</a>',
  header: {
    title: 'Produkty w koszyku:',
  },
  customer: {
    header: 'Dane klienta',
    properties: {
      email: 'Email',
    },
  },
  payment: {
    header: 'Metoda płatności',
    processing: 'Zostaniesz przekierowany na stronę płatności....',
    completed: 'Dziękujemy za dokonanie płatności. Twoje zamówienie zostało przekazane do realizacji',
  },
  paymentType: {
    card: {
      name: 'Zapłać za pomocą DotPay',
      description: 'W razie płatności zagranicznej <b>(np. PayPal)</b> prosimy o <a href="https://wyzszezasiegi.pl/contact" style="color: blue">kontakt</a>',
    },
  },
  messages: {
    addToCartSuccess: 'Produkt dodano do koszyka',
    addToCartError: 'Nie udało się dodać produktu do koszyka. Spróbuj ponownie za chwilę.',
    removeFromCartSuccess: 'Usunięto produkt z koszyka',
    removeFromCartError: 'Nie udało sie usunąć produktu z koszyka. Spróbuj ponownie za chwilę.',
  },
};

export default PolishCartTranslations;
