/* eslint-disable @typescript-eslint/naming-convention */

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { CartItem } from '../../../types';
import { Order } from '../../../types/Order.types';
import { Payment } from '../../../types/Payment.types';

type INITIAL_STATE_TYPE = {
  id: string | null,
  total: number,
  paymentType: string,
  customerEmail: string,
  products: CartItem[],
  isCartListOpen: boolean,
  order: Order | null,
  payment: Payment | null,
  isLoading: boolean,
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  id: null,
  total: 0,
  paymentType: 'dotPay',
  customerEmail: '',
  products: [],
  isCartListOpen: false,
  order: null,
  payment: null,
  isLoading: false,
};

const { name, reducer, ...rest } = createSlice({
  name: 'cart',
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setPayment: (state, { payload }: { payload: Payment }) => {
      state.payment = payload;
    },
    saveOrder: (state, { payload }: { payload: Order }) => {
      state.order = payload;
    },
    clearCart: (state) => {
      state.id = INITIAL_STATE.id;
      state.total = INITIAL_STATE.total;
      state.paymentType = INITIAL_STATE.paymentType;
      state.customerEmail = INITIAL_STATE.customerEmail;
      state.products = INITIAL_STATE.products;
      state.isCartListOpen = INITIAL_STATE.isCartListOpen;
      state.payment = INITIAL_STATE.payment;
    },
    updateCartData: (state, { payload }: { payload: any }) => {
      state.id = payload.id;
      state.total = payload.total;
      state.paymentType = payload.paymentType;
      state.customerEmail = payload.customerEmail;
      state.products = payload.products;
    },
    toggleOpenCartList: (state) => {
      state.isCartListOpen = !state.isCartListOpen;
    },
  },
});

const persistConfig = {
  key: name,
  storage,
  whitelist: ['id', 'total', 'paymentType', 'customerEmail', 'products', 'order', 'payment'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default {
  name,
  reducer: persistedReducer,
  ...rest,
};
