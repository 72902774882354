import React, { FC } from 'react';
import TagManager from 'react-gtm-module';

const generatePageViewEvent = (name: string) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'pageview',
      pagePath: window.location.pathname,
      pageTitle: name,
    },
  };

  return tagManagerArgs;
};

export default (Component: FC<any>, name = 'other') => (
  class extends React.Component {
    componentDidMount() {
      const tagManagerArgs = generatePageViewEvent(name);

      TagManager.dataLayer(tagManagerArgs);
    }

    componentDidUpdate() {
      const tagManagerArgs = generatePageViewEvent(name);

      TagManager.dataLayer(tagManagerArgs);
    }

    render() {
      return <Component {...this.props} />;
    }
  }
);
